import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as _91brand_93UP8oOQgtvmMeta } from "/vercel/path0/pages/admin/brands/[brand].vue?macro=true";
import { default as indexJPdrMcWERYMeta } from "/vercel/path0/pages/admin/brands/index.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as _91merchant_93dAuxog47L6Meta } from "/vercel/path0/pages/admin/merchants/[merchant].vue?macro=true";
import { default as index6VRKBjMZtEMeta } from "/vercel/path0/pages/admin/merchants/index.vue?macro=true";
import { default as _91post_93pSNzNkir4WMeta } from "/vercel/path0/pages/admin/posts/[post].vue?macro=true";
import { default as indexCJmAiJdkFbMeta } from "/vercel/path0/pages/admin/posts/index.vue?macro=true";
import { default as _91product_93nw8VFXoNQeMeta } from "/vercel/path0/pages/admin/products/[product].vue?macro=true";
import { default as indexr4iBpyk8AWMeta } from "/vercel/path0/pages/admin/products/index.vue?macro=true";
import { default as confirmILLw3md2pLMeta } from "/vercel/path0/pages/app/confirm.vue?macro=true";
import { default as indexWk8Hi46KRAMeta } from "/vercel/path0/pages/app/index.vue?macro=true";
import { default as login5DsufyKPUUMeta } from "/vercel/path0/pages/app/login.vue?macro=true";
import { default as indexBZ5b1RdHo0Meta } from "/vercel/path0/pages/app/merchant/index.vue?macro=true";
import { default as sign_45up5Q790QbQjQMeta } from "/vercel/path0/pages/app/sign-up.vue?macro=true";
import { default as indexFDYPRiOAmdMeta } from "/vercel/path0/pages/app/user/index.vue?macro=true";
import { default as brands4eh3EYtVIFMeta } from "/vercel/path0/pages/cheapest/brands.vue?macro=true";
import { default as merchantsifxtlJOHS8Meta } from "/vercel/path0/pages/cheapest/merchants.vue?macro=true";
import { default as productsPgL0BrGmUHMeta } from "/vercel/path0/pages/cheapest/products.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93hLe52mFaMuMeta } from "/vercel/path0/pages/legal/[...slug].vue?macro=true";
import { default as _91merchant_938qeTAqhOeCMeta } from "/vercel/path0/pages/merchants/[merchant].vue?macro=true";
import { default as index5qQH9tPaaKMeta } from "/vercel/path0/pages/merchants/index.vue?macro=true";
import { default as _91slug_933zF02U3eR7Meta } from "/vercel/path0/pages/posts/[slug].vue?macro=true";
import { default as indexG7XwsIruPAMeta } from "/vercel/path0/pages/posts/index.vue?macro=true";
import { default as indexDJjD9NcZ4YMeta } from "/vercel/path0/pages/products/index.vue?macro=true";
import { default as redirectp6UvtJam4iMeta } from "/vercel/path0/pages/snus/[brand]/[product]/[price]/redirect.vue?macro=true";
import { default as indexqHBoq8hmZMMeta } from "/vercel/path0/pages/snus/[brand]/[product]/index.vue?macro=true";
import { default as indexABGEBpxr7hMeta } from "/vercel/path0/pages/snus/[brand]/index.vue?macro=true";
import { default as indexJrB92HZOxJMeta } from "/vercel/path0/pages/snus/index.vue?macro=true";
import { default as nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbU } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91brand_93UP8oOQgtvmMeta?.name ?? "admin-brands-brand",
    path: _91brand_93UP8oOQgtvmMeta?.path ?? "/admin/brands/:brand()",
    meta: _91brand_93UP8oOQgtvmMeta || {},
    alias: _91brand_93UP8oOQgtvmMeta?.alias || [],
    redirect: _91brand_93UP8oOQgtvmMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/brands/[brand].vue").then(m => m.default || m)
  },
  {
    name: indexJPdrMcWERYMeta?.name ?? "admin-brands",
    path: indexJPdrMcWERYMeta?.path ?? "/admin/brands",
    meta: indexJPdrMcWERYMeta || {},
    alias: indexJPdrMcWERYMeta?.alias || [],
    redirect: indexJPdrMcWERYMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexG7R2Mya2P4Meta?.name ?? "admin",
    path: indexG7R2Mya2P4Meta?.path ?? "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    alias: indexG7R2Mya2P4Meta?.alias || [],
    redirect: indexG7R2Mya2P4Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91merchant_93dAuxog47L6Meta?.name ?? "admin-merchants-merchant",
    path: _91merchant_93dAuxog47L6Meta?.path ?? "/admin/merchants/:merchant()",
    meta: _91merchant_93dAuxog47L6Meta || {},
    alias: _91merchant_93dAuxog47L6Meta?.alias || [],
    redirect: _91merchant_93dAuxog47L6Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/merchants/[merchant].vue").then(m => m.default || m)
  },
  {
    name: index6VRKBjMZtEMeta?.name ?? "admin-merchants",
    path: index6VRKBjMZtEMeta?.path ?? "/admin/merchants",
    meta: index6VRKBjMZtEMeta || {},
    alias: index6VRKBjMZtEMeta?.alias || [],
    redirect: index6VRKBjMZtEMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/merchants/index.vue").then(m => m.default || m)
  },
  {
    name: _91post_93pSNzNkir4WMeta?.name ?? "admin-posts-post",
    path: _91post_93pSNzNkir4WMeta?.path ?? "/admin/posts/:post()",
    meta: _91post_93pSNzNkir4WMeta || {},
    alias: _91post_93pSNzNkir4WMeta?.alias || [],
    redirect: _91post_93pSNzNkir4WMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/posts/[post].vue").then(m => m.default || m)
  },
  {
    name: indexCJmAiJdkFbMeta?.name ?? "admin-posts",
    path: indexCJmAiJdkFbMeta?.path ?? "/admin/posts",
    meta: indexCJmAiJdkFbMeta || {},
    alias: indexCJmAiJdkFbMeta?.alias || [],
    redirect: indexCJmAiJdkFbMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/posts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_93nw8VFXoNQeMeta?.name ?? "admin-products-product",
    path: _91product_93nw8VFXoNQeMeta?.path ?? "/admin/products/:product()",
    meta: _91product_93nw8VFXoNQeMeta || {},
    alias: _91product_93nw8VFXoNQeMeta?.alias || [],
    redirect: _91product_93nw8VFXoNQeMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/products/[product].vue").then(m => m.default || m)
  },
  {
    name: indexr4iBpyk8AWMeta?.name ?? "admin-products",
    path: indexr4iBpyk8AWMeta?.path ?? "/admin/products",
    meta: indexr4iBpyk8AWMeta || {},
    alias: indexr4iBpyk8AWMeta?.alias || [],
    redirect: indexr4iBpyk8AWMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/products/index.vue").then(m => m.default || m)
  },
  {
    name: confirmILLw3md2pLMeta?.name ?? "app-confirm",
    path: confirmILLw3md2pLMeta?.path ?? "/app/confirm",
    meta: confirmILLw3md2pLMeta || {},
    alias: confirmILLw3md2pLMeta?.alias || [],
    redirect: confirmILLw3md2pLMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexWk8Hi46KRAMeta?.name ?? "app",
    path: indexWk8Hi46KRAMeta?.path ?? "/app",
    meta: indexWk8Hi46KRAMeta || {},
    alias: indexWk8Hi46KRAMeta?.alias || [],
    redirect: indexWk8Hi46KRAMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: login5DsufyKPUUMeta?.name ?? "app-login",
    path: login5DsufyKPUUMeta?.path ?? "/app/login",
    meta: login5DsufyKPUUMeta || {},
    alias: login5DsufyKPUUMeta?.alias || [],
    redirect: login5DsufyKPUUMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/login.vue").then(m => m.default || m)
  },
  {
    name: indexBZ5b1RdHo0Meta?.name ?? "app-merchant",
    path: indexBZ5b1RdHo0Meta?.path ?? "/app/merchant",
    meta: indexBZ5b1RdHo0Meta || {},
    alias: indexBZ5b1RdHo0Meta?.alias || [],
    redirect: indexBZ5b1RdHo0Meta?.redirect,
    component: () => import("/vercel/path0/pages/app/merchant/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45up5Q790QbQjQMeta?.name ?? "app-sign-up",
    path: sign_45up5Q790QbQjQMeta?.path ?? "/app/sign-up",
    meta: sign_45up5Q790QbQjQMeta || {},
    alias: sign_45up5Q790QbQjQMeta?.alias || [],
    redirect: sign_45up5Q790QbQjQMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/sign-up.vue").then(m => m.default || m)
  },
  {
    name: indexFDYPRiOAmdMeta?.name ?? "app-user",
    path: indexFDYPRiOAmdMeta?.path ?? "/app/user",
    meta: indexFDYPRiOAmdMeta || {},
    alias: indexFDYPRiOAmdMeta?.alias || [],
    redirect: indexFDYPRiOAmdMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/user/index.vue").then(m => m.default || m)
  },
  {
    name: brands4eh3EYtVIFMeta?.name ?? "cheapest-brands",
    path: brands4eh3EYtVIFMeta?.path ?? "/cheapest/brands",
    meta: brands4eh3EYtVIFMeta || {},
    alias: brands4eh3EYtVIFMeta?.alias || [],
    redirect: brands4eh3EYtVIFMeta?.redirect,
    component: () => import("/vercel/path0/pages/cheapest/brands.vue").then(m => m.default || m)
  },
  {
    name: merchantsifxtlJOHS8Meta?.name ?? "cheapest-merchants",
    path: merchantsifxtlJOHS8Meta?.path ?? "/cheapest/merchants",
    meta: merchantsifxtlJOHS8Meta || {},
    alias: merchantsifxtlJOHS8Meta?.alias || [],
    redirect: merchantsifxtlJOHS8Meta?.redirect,
    component: () => import("/vercel/path0/pages/cheapest/merchants.vue").then(m => m.default || m)
  },
  {
    name: productsPgL0BrGmUHMeta?.name ?? "cheapest-products",
    path: productsPgL0BrGmUHMeta?.path ?? "/cheapest/products",
    meta: productsPgL0BrGmUHMeta || {},
    alias: productsPgL0BrGmUHMeta?.alias || [],
    redirect: productsPgL0BrGmUHMeta?.redirect,
    component: () => import("/vercel/path0/pages/cheapest/products.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93hLe52mFaMuMeta?.name ?? "legal-slug",
    path: _91_46_46_46slug_93hLe52mFaMuMeta?.path ?? "/legal/:slug(.*)*",
    meta: _91_46_46_46slug_93hLe52mFaMuMeta || {},
    alias: _91_46_46_46slug_93hLe52mFaMuMeta?.alias || [],
    redirect: _91_46_46_46slug_93hLe52mFaMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/legal/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91merchant_938qeTAqhOeCMeta?.name ?? "merchants-merchant",
    path: _91merchant_938qeTAqhOeCMeta?.path ?? "/merchants/:merchant()",
    meta: _91merchant_938qeTAqhOeCMeta || {},
    alias: _91merchant_938qeTAqhOeCMeta?.alias || [],
    redirect: _91merchant_938qeTAqhOeCMeta?.redirect,
    component: () => import("/vercel/path0/pages/merchants/[merchant].vue").then(m => m.default || m)
  },
  {
    name: index5qQH9tPaaKMeta?.name ?? "merchants",
    path: index5qQH9tPaaKMeta?.path ?? "/merchants",
    meta: index5qQH9tPaaKMeta || {},
    alias: index5qQH9tPaaKMeta?.alias || [],
    redirect: index5qQH9tPaaKMeta?.redirect,
    component: () => import("/vercel/path0/pages/merchants/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933zF02U3eR7Meta?.name ?? "posts-slug",
    path: _91slug_933zF02U3eR7Meta?.path ?? "/posts/:slug()",
    meta: _91slug_933zF02U3eR7Meta || {},
    alias: _91slug_933zF02U3eR7Meta?.alias || [],
    redirect: _91slug_933zF02U3eR7Meta?.redirect,
    component: () => import("/vercel/path0/pages/posts/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexG7XwsIruPAMeta?.name ?? "posts",
    path: indexG7XwsIruPAMeta?.path ?? "/posts",
    meta: indexG7XwsIruPAMeta || {},
    alias: indexG7XwsIruPAMeta?.alias || [],
    redirect: indexG7XwsIruPAMeta?.redirect,
    component: () => import("/vercel/path0/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: indexDJjD9NcZ4YMeta?.name ?? "products",
    path: indexDJjD9NcZ4YMeta?.path ?? "/products",
    meta: indexDJjD9NcZ4YMeta || {},
    alias: indexDJjD9NcZ4YMeta?.alias || [],
    redirect: indexDJjD9NcZ4YMeta?.redirect,
    component: () => import("/vercel/path0/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: redirectp6UvtJam4iMeta?.name ?? "snus-brand-product-price-redirect",
    path: redirectp6UvtJam4iMeta?.path ?? "/snus/:brand()/:product()/:price()/redirect",
    meta: redirectp6UvtJam4iMeta || {},
    alias: redirectp6UvtJam4iMeta?.alias || [],
    redirect: redirectp6UvtJam4iMeta?.redirect,
    component: () => import("/vercel/path0/pages/snus/[brand]/[product]/[price]/redirect.vue").then(m => m.default || m)
  },
  {
    name: indexqHBoq8hmZMMeta?.name ?? "snus-brand-product",
    path: indexqHBoq8hmZMMeta?.path ?? "/snus/:brand()/:product()",
    meta: indexqHBoq8hmZMMeta || {},
    alias: indexqHBoq8hmZMMeta?.alias || [],
    redirect: indexqHBoq8hmZMMeta?.redirect,
    component: () => import("/vercel/path0/pages/snus/[brand]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: indexABGEBpxr7hMeta?.name ?? "snus-brand",
    path: indexABGEBpxr7hMeta?.path ?? "/snus/:brand()",
    meta: indexABGEBpxr7hMeta || {},
    alias: indexABGEBpxr7hMeta?.alias || [],
    redirect: indexABGEBpxr7hMeta?.redirect,
    component: () => import("/vercel/path0/pages/snus/[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJrB92HZOxJMeta?.name ?? "snus",
    path: indexJrB92HZOxJMeta?.path ?? "/snus",
    meta: indexJrB92HZOxJMeta || {},
    alias: indexJrB92HZOxJMeta?.alias || [],
    redirect: indexJrB92HZOxJMeta?.redirect,
    component: () => import("/vercel/path0/pages/snus/index.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta?.name ?? undefined,
    path: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta?.path ?? "/terms",
    meta: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta || {},
    alias: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta?.alias || [],
    redirect: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta?.redirect,
    component: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbU
  },
  {
    name: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta?.name ?? undefined,
    path: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta?.path ?? "/privacy",
    meta: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta || {},
    alias: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta?.alias || [],
    redirect: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbUMeta?.redirect,
    component: nuxt_643_4611_461__64unocss_43reset_640_4658_466_floating_45vue_645_462_462_rollup_644_4613_460_sass_641_4672_460_unocss_640_4658_466_vite_645_4626OHeVWOGbU
  }
]