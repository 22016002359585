

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"defaults":{"global":{"ripple":false},"VAppBar":{"border":"sm"},"VCard":{"border":"sm","rounded":"lg","variant":"outlined"},"VTextField":{"density":"compact","variant":"outlined"},"VTextarea":{"variant":"outlined"},"VSelect":{"density":"compact","variant":"outlined"},"VNavigationDrawer":{},"VTable":{"border":"sm","rounded":"lg","variant":"outlined"},"VSkeletonLoader":{"color":"background"}},"theme":{"defaultTheme":"light","variations":{"colors":["primary","secondary"],"darken":4,"lighten":4},"themes":{"light":{"name":"light","colors":{"primary":"#009688","secondary":"#00695C"},"dark":false},"dark":{"name":"dark","dark":true,"colors":{"background":"#0d121a","surface":"#15202b","primary":"#058079","secondary":"#084841","error":"#f44336","info":"#2196F3","success":"#59ce5d","warning":"#fb8c00"}}}},"ssr":true}')
  
  
  
  
  return options
}

