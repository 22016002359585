import revive_payload_client_q7FoO4VFBh from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_CNuzMAxIYT from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.13.3_rollup@4.13.0_vite@5.2.2_vue@3.4.25/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import vuetify_icons_7tchKk92aM from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.13.3_rollup@4.13.0_vite@5.2.2_vue@3.4.25/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_client_hints_client_hJeqSnLj4A from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.13.3_rollup@4.13.0_vite@5.2.2_vue@3.4.25/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-client-hints.client.mjs";
import unhead_Y6uxUZwat7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vGmyLFKa2z from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_83iiDi5ZeJ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.1_rollup@4.13.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_zMulKFYudL from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_UiZbQ4wbAs from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_ocSARWx9d2 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.13.0_vue@3.4.25/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_BquX2ooPz4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZiDVsjCNh1 from "/vercel/path0/node_modules/.pnpm/nuxt-csurf@1.5.1_rollup@4.13.0/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import plugin_wb1d7HinnP from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.13.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import script_ghufHnRlLA from "/vercel/path0/node_modules/.pnpm/@nuxtjs+turnstile@0.7.1_rollup@4.13.0/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import auth_redirect_mPJCmUTc99 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.1_rollup@4.13.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import i18n_KdBis1YWEL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.13.0_vue@3.4.25/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_kSjRkXfWBT from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_client_QHzhVBB2Ll from "/vercel/path0/.nuxt/nuxt-booster/plugin.client.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/vercel/path0/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_q7FoO4VFBh,
  vuetify_i18n_CNuzMAxIYT,
  vuetify_icons_7tchKk92aM,
  vuetify_client_hints_client_hJeqSnLj4A,
  unhead_Y6uxUZwat7,
  router_vGmyLFKa2z,
  supabase_client_83iiDi5ZeJ,
  payload_client_zMulKFYudL,
  check_outdated_build_client_UiZbQ4wbAs,
  plugin_vue3_ocSARWx9d2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BquX2ooPz4,
  plugin_ZiDVsjCNh1,
  plugin_wb1d7HinnP,
  script_ghufHnRlLA,
  auth_redirect_mPJCmUTc99,
  i18n_KdBis1YWEL,
  chunk_reload_client_kSjRkXfWBT,
  plugin_client_QHzhVBB2Ll,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]