import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_unocss@0.58.6_vite@5.2.2/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path0/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}