import {aliases,mdi} from 'vuetify/iconsets/mdi-svg'
export const enabled = true
export const isDev = false
export function iconsConfiguration() {

  return {
    defaultSet: 'mdi',
    aliases,
    sets: { mdi }
  }
}

